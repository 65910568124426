/***************
FORM STYLING
***************/

label {
	display:block;
	margin-bottom:5px;
}
input[type=text],input[type=email],input[type=phone],input[type=password],input[type=number],input[type=search], textarea {
	background: darken(white, 10%);
	padding:5px;
	outline:none;
	border: none;
	height: $line-height;
	width:300px;
	margin-bottom: .5rem;

	&:focus {
		border: 1px solid $primary-color;
	}

	&.full-width {
		width:100%;
	}
}
textarea {
	height:$line-height*3;
}

form {
	margin-bottom:$padding;

	// Wrap each form input/element in a field div
	.field {
		margin-bottom:$padding/2;
	}

	// Notes go under the input fields
	.note {
		margin-top:5px;
		color:$subtle-color;
	}

	// Required label
	.req {
		font-style: italic;
	}

	// Validation, add error class to the item div
	.error {

		// Validation reason
		.reason {
			margin-top:5px;
			color:$bad-color;
		}

		input[type=text],input[type=email],input[type=phone],input[type=password],input[type=number],input[type=search], textarea{
			border-color:$bad-color;
		}
	}
}
