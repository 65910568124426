// Reset using Normalize
@import "normalize";

// Import fonts from Google
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Roboto:300');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');

// Site configuration
@import "config";

// Import Partials
@import "partials/typography";
@import "partials/mixins";

// Modules
@import "modules/buttons";
@import "modules/forms";
@import "modules/tables";
@import "modules/syntax";

// Mobile up base styles
@import "breakpoints/mobileup";

// Import media queries add more as you desire
@media only screen and (min-width: 800px) { @import "breakpoints/800up";}

// If IE support is needed you can add them here or create another stylesheet for specific IE fixes
