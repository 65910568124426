/***************
A MORE MODERN SCALE
http://typecast.com/blog/a-more-modern-scale-for-web-typography
***************/

body {
  font-size: 1em;
  /* equivalent to 16px */
  line-height: 1.45;
  /* equivalent to 20px */

  .intro {
    font-size: 1.25em;
    line-height: 1.7;
  }
}

code {
  font-family: $font-choice3;
  font-size: 1em;
  line-height: 1.45;
}

@media (min-width: 43.75em) {
  body {
    font-size: 1em;
    /* equivalent to 16px */
    line-height: 1.375;
    /* equivalent to 22px */
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: .5rem 0 1.5rem;
  font-family: $font-choice1;
}

// Headings
h1, .h1 {
  font-size: 2em; // 2x body copy size = 32px
  line-height: 1.25; // 45px / 36px
}

@media (min-width: 43.75em) {
  h1, .h1 {
    font-size: 2.5em; // 2.5x body copy size = 40px
    line-height: 1.125;
  }
}

@media (min-width: 56.25em) {
  h1, .h1 {
    font-size: 3em; // 3x body copy size = 48px
    line-height: 1.05; // keep to a multiple of the 20px line height and something more appropriate for display headings
  }
}

h2, .h2 {
  font-size: 1.625em; // 1.625x body copy size = 26px
  line-height: 1.15384615; // 30px / 26px
}

@media (min-width: 43.75em) {
  h2, .h2 {
    font-size: 2em; // 2x body copy size = 32px
    line-height: 1.25;
  }
}

@media (min-width: 56.25em) {
  h2, .h2 {
    font-size: 2.25em; // 2.25x body copy size = 36px
    line-height: 1.25;
  }
}

h3, .h3 {
  font-size: 1.375em; // 1.375x body copy size = 22px
  line-height: 1.13636364; //25px / 22px
}

@media (min-width: 43.75em) {
  h3, .h3 {
    font-size: 1.5em; // 1.5x body copy size = 24px
    line-height: 1.25;
  }
}

@media (min-width: 56.25em) {
  h3, .h3 {
    font-size: 1.75em; // 1.75x body copy size = 28px
    line-height: 1.25;
  }
}

h4, .h4 {
  font-size: 1.125em; // 1.125x body copy size = 18px
  line-height: 1.11111111;
}

@media (min-width: 43.75em) {
  h4, .h4 {
    line-height: 1.22222222; // 22px / 18px
  }
}

blockquote {
  font-size: 1.25em; // 20px / 16px
  line-height: 1.25; // 25px / 20px
  padding: 0;
}

@media (min-width: 43.75em) {
  blockquote {
    font-size: 1.5em; // 24px / 16px
    line-height: 1.45833333; // 35px / 24px
  }
}
