/***************
GLOBAL STYLES
***************/

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
	font-family: $font-choice2;
	line-height: 1.75;
	font-size: 112.5%; // 18px base font size
	color: $black;
	overflow-x: hidden;
  margin: 0;

	p , ul {
		margin: 0 0 2rem;
	}
}

// Text Selection Styling
::-moz-selection {
	background: $primary-color;
	color: white;
}

::selection {
	background: $primary-color;
	color: white;
}

// Media Styling
img,video,audio,iframe,object{
	max-width:100%;
}

// Layout
.container {
  max-width: 610px;
	padding: 0 20px;
	margin-left: auto;
	margin-right: auto;
}

.dropcap {
  color: $primary-color;
  margin-right: .45rem;
}

/***************
LINK STYLING
***************/

a {
	color: $link-color;

	&:hover {
		color: darken($link-color, 15%);
	}
}


/***************
MENU STYLING
***************/

.nav-collapse {
	z-index: 1;

	ul {
	  margin: 0;
	  padding: 0;
	  width: 100%;
	  display: block;
	  list-style: none;

		li {
		  width: 100%;
		  display: block;
		  background: $background-color;
		  border-bottom: 2px solid lighten($background-color, 5%);

		  a {
		  	color: white;
		  	text-decoration: none;
		  	display: block;
		  	padding: 5px 2rem;
		  }
		}
	}
}

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
  clear: both;
  width: 100%;
}

.nav-collapse.opened {
  max-height: 9999px;
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  float: right;
  line-height: 2em;
  margin-top: .5em;
  background-color: $background-color;
  border-radius: 4px;
  padding: 0 .5em;
  color: white;
  text-decoration: none;
  position: relative;
  right: 2rem;
  transition: all 0.2s ease-in-out;

  &:hover {
  	color: white;
  	background-color: darken($background-color, 10%);
  }
}


/***************
LIST STYLING
***************/

.noList {
	list-style:none;
	padding-left:0;
	margin-left:0;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 0 1.5rem;
}

.post ul ul,
.post ul ol,
.post ol ul,
.post ol ol {
  margin: 0;
}


/***************
PAGE STYLES
***************/

.pageTitle {
	margin: 2rem auto 1em;
	text-align: center;
}

.content {
	padding-top: 1em;
}


/***************
HEADER STYLING
***************/

.header {
	background-color: $background-color;
	background-image: url('../img/background.jpg');
	background-size: cover;
	background-position: center center;
	color: white;
	padding: 0;
	height: 3em;
  text-align: center;

	.container {
		padding: 0;
    max-width: 100%;
	}

	h1 {
		margin: 0;
		float: left;
		padding-left: 2rem;
		font-size: 1em;
		line-height: 3em;
		font-family: $font-choice1;
		font-weight: 800;
		text-transform: capitalize;

    text-shadow: 2px 2px 4px #040404;

		a {
			color: white;
			text-decoration: none;
		}
	}
}


/***************
POST STYLING
***************/

.post {

	.postTitle {
		text-align: center;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

	blockquote {
		clear: both;
		margin: 2.5em 0;
		padding: 0;
		line-height: 1.8;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: -1.25em;
			background: $background-color;
			display: block;
			height: 2px;
			width: 150px;
			left: calc(50% - 75px);
		}

		&:after {
			content: "";
			position: absolute;
			bottom: -1.25em;
			background: $background-color;
			display: block;
			height: 2px;
			width: 150px;
			left: calc(50% - 75px);
		}
	}

	.meta {
		text-align: center;
		color: lighten(black, 50%);
		font-family: $font-choice1;
		font-weight: 300;
	}

	figure {
		margin: 3rem 0;

		figcaption {
			text-align: center;
			font-size: .9em;
			font-style: italic;
			color: lighten($black, 30%);
		}

		img {
			margin: 0 auto;
			display: block;
		}
	}

	img {
		margin-bottom: 2rem;
	}

	.highlight {
		clear: both;
		margin-bottom: 2rem;
	}

	.featuredImage {
		position: relative;
		margin-top: 2rem;

		img {
			margin-bottom: 0;
		}
	}
}


/***************
POST NAV STYLING
***************/

.postNav {
	border-top: 1px solid darken(white, 10%);
	border-bottom: 1px solid darken(white, 10%);
	overflow: hidden;

	.prev, .next {
		display: block;
		width: 100%;
		height: 5rem;
		overflow: hidden;
		position: relative;
		font-family: $font-choice1;
		font-weight: 800;
		font-size: .9em;
		text-transform: uppercase;
		text-align: center;

		img {
			z-index: 1;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			width: 100%;
			height: auto;
		}

		span {
			z-index: 10;
			position: relative;
			padding: .5rem;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			line-height: 1.4;
		}

		&.image {
			text-decoration: none;
			color: white;
			text-shadow: 1px 1px 3px rgba(0,0,0,.8);
			padding: 0 .5rem;
			position: relative;
			transition: all 0.2s ease-in-out;
		}

		&:hover {
			&.image {
				opacity: .8;
			}
		}
	}

	.prev {
		span {
			left: 0;
		}
	}

	.next {
		span {
			right: 0;
		}
	}
}


/***************
FOOTER STYLING
***************/

.footer {
	text-align: center;
	padding: 2rem 0;
	clear: both;

	.container {
		border-top: 4px solid darken(white, 15%);
		padding-top: .5em;
    max-width: 90%;
	}

	.copy, .footer-links {
		font-size: .8em;
	}

	.copy {
		margin-bottom: 1rem;
	}

	&-links {
		ul {
			text-align: center;

			li {
				display: inline-block;

				a {
					display: inline-block;
					width: 30px;
					height: 30px;
					transition: all 0.2s ease-in-out;
					font-size: 30px;

					svg {
						display: block;
						width: 100%;
						height: 100%;

						.outer-shape {
							fill: $primary-color;
						}

						.inner-shape {
							fill: white;
						}
					}
				}


				&:hover {
					a {
						svg {
							.outer-shape {
								fill: darken($primary-color, 10%);
							}
						}
					}
				}
			}
		}
	}
}


/***************
PAGINATION STYLING
***************/

.pagination {
	.next {
		float: left;
	}

	.previous {
		float: right;
	}
}


/***************
POST LIST STYLING
***************/

.posts {
	li {
		margin-bottom: 4rem;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: -2rem;
			left: 0;
			background: darken(white, 10%);
			height: 2px;
			width: 60px;
		}

		a {
			text-decoration: none;

			&:hover {
				//text-decoration: underline;
			}
		}

		.date {
			color: lighten(black, 50%);
			font-family: $font-choice1;
			font-weight: 300;
			font-size: .8em;
		}

		h3 {
			margin: 0 0 .5em;
		}
	}
}
