/***************
BUTTON DEFAULTS
***************/

%btn {
  background-color: $primary-color;
  display: inline-block;
  position: relative;
  font-family: $font-choice1;
  text-decoration: none;
  color: $white;
  font-size: 1em;
  line-height: 1.2em;
  font-weight: normal;
  padding: .5em 1.5em;
  border: 0;
  border-radius: 4px; 
  cursor: pointer;
  margin-bottom: .5em;
  transition: background-color 0.14s ease-in-out;

  // hovering on the btn
  &:hover, &:focus {
    color: $white;
    text-decoration: none;
    background-color: darken($primary-color, 10%);
  } // end hover

  // clicking on the button
  &:active {
    top: 1px; // adds a tiny hop to the click
  } // end active

} // end .button


// Default button class
.button {
  @extend %btn;

  &-secondary {
    @extend %btn;
    background-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }
 
  &-tertiary {
    @extend %btn;
    background-color: $tertiary-color;

    &:hover {
      background-color: darken($tertiary-color, 10%);
    }
  }  
}


// Outline Button
.button__outline {  
  @extend %btn;
  background-color: transparent;
  border: 3px solid $primary-color;
  color: $primary-color;

  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}

